<template>
  <div v-if="type=='Grouped'">
    <v-icon @click="$emit('click','addGroup')" title="add" :size="size">mdi-plus</v-icon>
  </div>
</template>
<script>
import { arrayFindInArray } from "@/js/helper.js";
export default {
  props: {
    size: Number,
    result: Object,
  },
  computed: {
    type() {
      let valueArray = arrayFindInArray(
        this.result.valueLine.attribute,
        this.result.fields.attribute.associatedOption
      );
      //     tag = valueArray[1];
      let type = valueArray[2];
      //     tag = tag + "*";
      return type;
    },
  },
};
</script>